import React from "react";
import Image from "next/image";
import logo from "../public/logo.svg";
import { useRouter } from "next/router";

type Props = {};

export default function Index({}: Props) {
  const router = useRouter();
  return (
    <div
      className="flex flex-col my-auto h-screen justify-center gap-4 cursor-pointer"
      onClick={() => router.push("/")}
    >
      <Image alt="Koala Write logo" src={logo} height={50} />
      <div className="text-center text-lg">
        We are sorry, but the page you are looking for does not exist.😔
      </div>
    </div>
  );
}
